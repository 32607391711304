import React, { useState } from "react";
import { ModalComponent } from "./Modal";
import { useForm } from "../hooks/useForm";
import "../styles/components/hero1.css";

import axios from "axios";

export const Hero = ({ title, desc, home }) => {
  const [modalShow, setModalShow] = useState(false);
  const [state, setState] = useState({ loading: false, caratula: {} });
  const [{ searchText }, handleInputChange, reset] = useForm({
    searchText: "",
  });
  // http://www.conservadorcopiapo.cl/api/?numero=
  const handleSubmit = async (e) => {
    e.preventDefault();
    setState({ loading: true });
    setModalShow(true);
    reset();
    fetch(`https://www.conservadorcopiapo.cl/api/?numero=${searchText}`)
      .then(
        (response) => response.json()
        // setState({ loading: false, caratula: data.data });
      )
      .then((data) => setState({ loading: false, caratula: data }))
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="hero">
      <ModalComponent
        state={state}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <div className="wrap">
        <div className="hero__description animate__animated animate__fadeInDown">
          <h1>{title}</h1>
          <p>{desc}</p>
          {home && (
            <form onSubmit={handleSubmit} className="hero__input">
              <input
                type="text"
                onChange={handleInputChange}
                value={searchText}
                name="searchText"
                className="form-control"
                placeholder="Estado Carátula"
                autoComplete="off"
                required
              />
              <button className="btn btn-primary" type="submit">
                <i className="fas fa-search"></i>Buscar
              </button>
            </form>
          )}
        </div>
        {home && (
          <div className="hero__notif animate__animated animate__fadeInDown">
            <div className="hero__header">
              <i className="fas fa-info-circle"></i>
            </div>
            <div className="hero__body">
              <p style={{ textAlign: "center" }}>
                <b>ESTIMADOS USUARIOS</b>
              </p>
              <p style={{ textAlign: "justify" }}>
                {/*En relación a la crisis sanitaria que nos afecta, CBRC de Copiapó, 
                como servicio esencial del estado, mantiene su atención a público 
                en cualquiera de las fases decretadas por la autoridad sanitaria.
                <br /> Seguimos funcionando con todos los protocolos sanitarios 
                adecuados y los turnos de personal necesarios para entregar 
                la mejor atención con la mayor seguridad posible.
                <br /> */}

                 
                
                {/*y 
                así evitar el riesgo que implica los traslados.*/}

                                           
                <p style={{ textAlign: "justify" }}>
                
                <br />
                Para requerir Inscripciones de Propiedad, hipotecas y prohibiciones, haga clic <a href="https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/inscripcion-propiedad" target="_blank"><b>aquí</b></a>

                {/* "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/inscripcion-propiedad", */}

                <br />

                <br />
                Para solicitar copias de inscripciones, haga click <a href="https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea" target="_blank"><b>aquí</b></a>

                {/* "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea", */}

                <br />
                
                <br />
                Para solicitar copias de escrituras públicas, llene el siguiente formulario <a href="https://docs.google.com/forms/d/e/1FAIpQLSchjSYqHG9rpO4Cnt_Od-fQ0q6NhL-Gur1HvaKePuPWpWntPA/viewform" target="_blank"><b>aquí</b></a>

                {/* "https://forms.gle/wHnZ73E1qTyZ21uZ9", */}
                 
                <br />

                <br />
                Consultar índice de propiedad, clic  <a href="https://conservadoresdigitales.cl/conservador/copiapo/consultas-en-linea/indice-de-propiedad " target="_blank"><b>aquí</b></a>

                {/* "https://conservadoresdigitales.cl/conservador/copiapo/consultas-en-linea/indice-de-propiedad ", */}
                 
  
                <br />
                
                
                
                
                
                
                
                
                {/*
                
                <br />
                <br />
                Para solicitar copias de escrituras públicas y otros documentos, llene el siguiente formulario 
                link:

                "https://forms.gle/wHnZ73E1qTyZ21uZ9",
                 
  
                <br />
                */} 
                

                {/*
                
                <br />
                <br />
                Consultar índice de propiedad, clic aquí https://conservadoresdigitales.cl/conservador/copiapo/consultas-en-linea/indice-de-propiedad </b>
 
  
                <br />

                */}                                 
                


                {/*
                <b>Elecciones de Gobernador Regional, Consejeros de los Gobiernos Regionales, Alcaldes y Concejales. </b>

                <br />
                <br />
                Para excusas enviar datos electorales, Nombre , Mesa  Designación como vocal y motivo al correo <b>excusasjuntaelectoral@conservadorcopiapo.cl</b> 
                <br />
                */}
                
                                                                                            
                {/*
                Para consultas relacionadas con tramites Web, comuniquese al correo <b>web@conservadorcopiapo.cl</b>
                <br />
                <br />
                */}
                

                {/*
                Estimados, informamos que las solicitudes por pagina web estaran en receso por mantención de sistema web, solicitamos realizar sus solicitudes al correo <b>contacto@conservadorcopiapo.cl</b>
                <br />
                <br />
                */}

                {/*

                El Oficio de CONSERVADOR DE BIENES RAICES DE COPIAPO esta experimentando problemas serios de comunicación a través de sus canales digitales (pagina web, correos electrónicos, chat de pagina web, etc) por tanto, y como medida alternativa momentánea, deberá contactarse a través del correomail personal del funcionario a cargo de Departamento de Cotizaciones de CBRC: danielvicenciopulgar@gmail.com
                
                */}
                {/*Presentamos problemas con nuestros servicios Correos y  Web , comuniquese a la central <b>52 - 2352620</b>  */}

                <br />
                <br />
                
                {/*
                
                <p style={{ textAlign: "center" }}>
                <b> Estimado Usuario(a)Por motivos de duelo de nuestro personal, el martes 10 septiembre el horario de atención a público será de 9:00 a 12:00 horas. Agradecemos su comprensión. </b> <br />
                
                </p>
                
                */}


                {/*
                
                <p style={{ textAlign: "center" }}>
                <b>Horario de Atención Martes 31 de Diciembre 09:00 hrs a 14:00 hrs.</b> <br />
                </p>
                
               */}
               
                <i className="far fa-clock"></i> <b>Horario de Atención presencial y virtual (chat):</b> Lunes a viernes de 09:00 hrs a 16:00 hrs.  horario continuado.
                <br />
                <br />
                </p>                                
              </p>

              {/*

              <p style={{ textAlign: "center" }}>
              Por su atención y compresión muchas gracias.
              </p>  

              */}     

              </div>
              </div>
      )}
      </div>
    </div>
  );
};